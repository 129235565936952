import { render, staticRenderFns } from "./ControlRowDetails.vue?vue&type=template&id=f5ed4e3a&scoped=true"
import script from "./ControlRowDetails.vue?vue&type=script&lang=ts"
export * from "./ControlRowDetails.vue?vue&type=script&lang=ts"
import style0 from "./ControlRowDetails.vue?vue&type=style&index=0&id=f5ed4e3a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5ed4e3a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_5dda59c3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f5ed4e3a')) {
      api.createRecord('f5ed4e3a', component.options)
    } else {
      api.reload('f5ed4e3a', component.options)
    }
    module.hot.accept("./ControlRowDetails.vue?vue&type=template&id=f5ed4e3a&scoped=true", function () {
      api.rerender('f5ed4e3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cards/controltable/ControlRowDetails.vue"
export default component.exports