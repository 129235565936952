import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=fef76b72"
import script from "./Footer.vue?vue&type=script&lang=ts"
export * from "./Footer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_5dda59c3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fef76b72')) {
      api.createRecord('fef76b72', component.options)
    } else {
      api.reload('fef76b72', component.options)
    }
    module.hot.accept("./Footer.vue?vue&type=template&id=fef76b72", function () {
      api.rerender('fef76b72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/global/Footer.vue"
export default component.exports